
* {
  box-sizing: border-box;
}

body {
  text-align: center;
  line-height: 1.5;
}

button:focus {
  outline: none;
}