
.pet-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pet-img {
  width: 30%;
  height: 400px;
  border-radius: 8px;
}

.pet-attr, .pet-story {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  list-style: none;
  font-size: 18px;
}

.pet-story {
  margin: 0;
}

.change-button {
  font-size: 16px;
  height: 30px;
  border-radius: 8px;
  background-color: rgb(143, 218, 228);
}

.adopt-button {
  margin: 1% 0;
  font-size: 18px;
  font-weight: 600; 
  height: 40px;
  min-width: 60px;
  border-radius: 8px;
  background-color: rgb(143, 218, 228);
}

.adopt-button:hover, .change-button:hover{
  background-color: rgb(92, 179, 190);
}

.next-button {
  margin: 1% 1%;
  font-size: 18px;
  font-weight: 600; 
  height: 40px;
  min-width: 60px;
  border-radius: 8px;
  background-color: rgb(161, 216, 73);
}

.next-button:hover {
  background-color: rgb(125, 182, 34);
}